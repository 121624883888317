import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 */
interface ImageProps {
  className?: string;
  src: any;
  alt?: string;
  width?: number;
  height?: number;
}

const Image = ({ className, src, alt = '', height, width }: ImageProps) => {
  const imgStyle = height && width
    ? {
      height,
      width
    }
    : {};

  if (!src) {
    return <div>Picture not found</div>
  }

  return <Img fluid={src} alt={alt} imgStyle={imgStyle} className={className} />
}

export default Image
